.oracle-section {
    padding: 60px 20px;
    color: #e0e0e0;
    text-align: center;
    font-family: 'Courier New', monospace;
  }
  
  .oracle-section h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #00ffcc;
    text-shadow: 0 0 8px rgba(0, 255, 204, 0.7);
  }
  
  .prophecy-box {
    padding: 20px;
    background: rgba(39, 39, 82, 0.9);
    color: #fff;
    border: 2px dashed #5e5eff;
    cursor: pointer;
    transition: background 0.3s, box-shadow 0.3s ease-in-out;
    display: inline-block;
    margin-top: 20px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.4);
    border-radius: 8px;
  }
  
  .prophecy-box:hover {
    background: rgba(59, 59, 107, 0.9);
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.7);
  }
  