.encrypted-stream {
    padding: 40px 20px;
    color: #00ffcc;
    text-align: center;
    font-family: 'Courier New', monospace;
  }
  
  .stream-container {
    padding: 20px;
    border: 1px solid #00ffcc;
    border-radius: 8px;
    display: inline-block;
  }
  
  .stream-text {
    font-size: 1.2em;
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 0.7;
    }
    50% {
      opacity: 1;
    }
  }
  