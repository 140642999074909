.hidden-node {
    padding: 20px;
    color: #e0e0e0;
    text-align: center;
    border: 1px solid #5e5eff;
    cursor: pointer;
    transition: background 0.3s;
    max-width: 300px;
    margin: 20px auto;
  }
  
  .hidden-node:hover {
    background: #37375b;
  }
  
  .node-hidden, .node-revealed {
    font-size: 1em;
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 0.8;
    }
    50% {
      opacity: 1;
    }
  }
  