.ai-chatbot {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    max-height: 400px;
    background: rgba(0, 0, 0, 0.9);
    border: 1px solid #00ffcc;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 255, 204, 0.5);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 1000;
  }
  
  .chat-window {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  
  .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background: rgba(15, 15, 20, 0.9);
    font-family: 'Courier New', monospace;
    color: #e0e0e0;
  }
  
  .chat-message {
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
  }
  
  .chat-message.user {
    background: rgba(0, 255, 204, 0.2);
    text-align: right;
  }
  
  .chat-message.ai {
    background: rgba(0, 0, 0, 0.5);
  }
  
  .chat-input {
    display: flex;
    border-top: 1px solid #00ffcc;
  }
  
  .chat-input input {
    flex: 1;
    padding: 10px;
    border: none;
    outline: none;
    background: #1a1a1a;
    color: #00ffcc;
  }
  
  .chat-input button {
    padding: 10px 15px;
    border: none;
    outline: none;
    background: #00ffcc;
    color: #121212;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .chat-input button:hover {
    background: #00cccc;
  }
  @media (max-width: 768px) {
    .ai-chatbot {
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      max-width: 320px;
    }
  }