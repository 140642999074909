.hacker-terminal-section {
  padding: 40px 20px;
  margin: 20px auto;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.85);
  border: 1px solid #00ffcc;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 255, 204, 0.5);
  overflow: hidden;
}

.terminal-output {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  font-family: 'Courier New', monospace;
  color: #e0e0e0;
  background: rgba(15, 15, 20, 0.9);
}

.terminal-line {
  margin-bottom: 5px;
}

.terminal-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #00ffcc;
}

.prompt-symbol {
  color: #00ffcc;
  margin-right: 5px;
  font-family: 'Courier New', monospace;
}

.terminal-input input {
  flex: 1;
  padding: 8px;
  background: transparent;
  border: none;
  outline: none;
  color: #00ffcc;
  font-family: 'Courier New', monospace;
}

.terminal-input input::placeholder {
  color: #007b7b;
}
