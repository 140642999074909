.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  z-index: -1; /* Ensure it is behind content but above the background */
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Courier New', monospace;
  background: #0f0f1c;
  color: #e0e0e0;
}

button, a {
  font-family: inherit;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}
