.decryption-challenge-section {
    padding: 60px 20px;
    color: #e0e0e0;
    text-align: center;
    font-family: 'Courier New', monospace;
  }
  
  .decryption-challenge {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: rgba(0, 0, 0, 0.9);
    border: 1px solid #00ffcc;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 255, 204, 0.5);
  }
  
  .challenge-content {
    text-align: center;
  }
  
  .challenge-message {
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
  
  .input-area {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: center;
  }
  
  .input-area input {
    flex: 1;
    max-width: 200px;
    padding: 8px;
    background: transparent;
    border: 1px solid #00ffcc;
    color: #00ffcc;
    outline: none;
    border-radius: 5px;
  }
  
  .input-area button {
    padding: 8px 15px;
    background: #00ffcc;
    border: none;
    color: #121212;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s;
  }
  
  .input-area button:hover {
    background: #00cccc;
  }
  
  .reward-message {
    margin-top: 15px;
    color: #00ffcc;
    font-size: 1rem;
  }
  