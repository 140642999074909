.background-canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -9999;
    pointer-events: none;
    background: radial-gradient(circle, #0b0c10, #000);
  }
  
  .background-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay for better text readability */
    z-index: -1;
  }
  .background-lines {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 255, 204, 0.05) 1px, transparent 1px),
                linear-gradient(to bottom, rgba(0, 255, 204, 0.05) 1px, transparent 1px);
    background-size: 80px 80px;
    z-index: -9998;
    animation: moveBackground 30s linear infinite;
  }
  
  @keyframes moveBackground {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 100%;
    }
  }
  
  