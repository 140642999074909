.hidden-message-container {
  position: relative;
  padding: 40px;
  text-align: center;
}

.hidden-text {
  font-size: 1.2em;
  color: #12122b;
  transition: color 0.5s;
  cursor: pointer;
}

.hidden-text.revealed {
  color: #00ffcc;
}

.code-symbol {
  color: #ff00ff;
}
