.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Adjusted height to 100vh instead of 110vh */
    color: #e0e0e0;
    text-align: center;
    overflow: hidden;
    position: relative;
    padding: 10px;
    backdrop-filter: blur(3px);
    margin-top: -20px; /* Pushes the section upwards */
  }
  
  .big-title {
    font-size: 4rem;
    color: #00ffcc;
    font-family: 'Fira Code', monospace; /* Suggested modern hacker-style font */
    text-shadow: 0 0 5px #00ffcc, 0 0 10px #00ffcc, 0 0 20px rgba(0, 255, 204, 0.5);
    animation: glitch 2.5s infinite, flicker 3s linear infinite;
    white-space: nowrap;
    margin-bottom: 25px;
    position: relative;
    overflow: hidden;
  }
  @media (max-width: 768px) {
    .big-title {
      font-size: 2.5rem; /* Reduce font size for smaller screens */
      margin-bottom: 15px; /* Adjust margins for spacing */
    }
  }
  
  @media (max-width: 480px) {
    .big-title {
      font-size: 2rem; /* Further reduction for very small screens */
      margin-bottom: 10px;
    }
  }
  
  /* Glitch Effect Keyframes */
  @keyframes glitch {
    0% {
      text-shadow: 0 0 5px #00ffcc, 0 0 10px #00ffcc, 0 0 20px rgba(0, 255, 204, 0.5);
      transform: translateX(0);
    }
    15% {
      text-shadow: -2px -2px 8px #ff00ff, 2px 2px 12px #00ffcc;
      transform: translateX(-2px) skewX(-3deg);
    }
    30% {
      text-shadow: 2px 2px 5px #ff00ff, -2px -2px 10px #00ffcc;
      transform: translateX(2px) skewX(3deg);
    }
    45% {
      text-shadow: 0 0 5px #00ffcc, 0 0 10px #00ffcc, 0 0 20px rgba(0, 255, 204, 0.5);
      transform: translateX(0);
    }
  }
  
  /* Flicker Effect Keyframes */
  @keyframes flicker {
    0%, 100% {
      opacity: 1;
    }
    10% {
      opacity: 0.85;
    }
    20% {
      opacity: 0.7;
    }
    30% {
      opacity: 0.9;
    }
    40% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.8;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 0.9;
    }
    80% {
      opacity: 0.75;
    }
    90% {
      opacity: 0.95;
    }
  }
  
  
  .hero-content {
    background: rgba(20, 20, 20, 0.85);
    padding: 50px; /* Increased padding for a more spacious feel */
    border-radius: 12px;
    border: 1px solid rgba(0, 255, 204, 0.2);
    box-shadow: 0 0 20px rgba(0, 255, 204, 0.1);
    max-width: 800px; /* Allow more space for content */
    text-align: center;
  }
  
  .hero-logo {
    max-width: 250px; /* Increased size for the logo */
    margin-bottom: 20px;
    filter: drop-shadow(0 0 8px rgba(0, 255, 204, 0.5));
    transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
    border-radius: 15px; /* Rounded corners */
  }
  
  .hero-logo:hover {
    transform: scale(1.1);
    filter: drop-shadow(0 0 15px rgba(0, 255, 204, 0.8));
  }
  
  .hero-subtext {
    font-size: 1.6rem; /* Slightly larger subtext */
    color: #bfbfbf;
    margin-top: 20px;
    opacity: 0.9;
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.3);
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .social-icon {
    width: 50px;
    height: 50px;
    margin: 0 10px;
    position: relative;
    transition: transform 0.3s ease, filter 0.3s ease;
    cursor: pointer;
    filter: drop-shadow(0 0 8px rgba(0, 255, 204, 0.5));
    overflow: hidden; /* Ensure the overlay stays within the icon boundaries */
    border-radius: 50%; /* Add if you want a circular effect for the icons */
  }
  
  .social-icon::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 255, 204, 0.2); /* Semi-transparent hacking glow color */
    mix-blend-mode: overlay; /* Blending mode to create a stylish effect */
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    border-radius: inherit; /* Match the shape of the icon */
  }
  
  .social-icon:hover {
    transform: scale(1.1);
  }
  
  .social-icon:hover::after {
    opacity: 1;
    transform: scale(1.15);
  }
  
  
  .reveal-button {
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.8);
    color: #00ffcc;
    border: 1px solid #00ffcc;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
    margin-top: 20px;
    box-shadow: 0 0 12px rgba(0, 255, 204, 0.3);
  }
  
  .contract-address-container {
    margin-top: 15px;
    background: rgba(0, 0, 0, 0.8);
    padding: 12px 20px;
    border-radius: 8px;
    border: 1px solid rgba(0, 255, 204, 0.4);
    max-width: 85%;
  }
  .reveal-button {
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.8);
    color: #00ffcc;
    border: 1px solid #00ffcc;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
    margin-top: 20px;
    box-shadow: 0 0 12px rgba(0, 255, 204, 0.3);
  }
  
  .reveal-button:hover {
    background: #00ffcc;
    color: #121212;
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.7);
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
    .hero-section {
      height: auto;
      padding: 40px 10px;
    }
  
    .big-title {
      font-size: 2.5rem;
      margin-bottom: 15px;
    }
  
    .hero-logo {
      max-width: 150px;
    }
  
    .social-icons {
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .social-icon {
      width: 40px;
      height: 40px;
      margin: 5px;
    }
  
    .hero-content {
      padding: 20px;
      max-width: 90%;
    }
  }
